<template>
  <div class="aboutus-cnt2">
    <h6 class="h6">商务合作 - Business cooperation</h6>
    <div class="cnt">
      <div class="box">
        <h5 class="h5">印刷渠道合作</h5>
        <p class="p">---</p>
        <p class="p">联系人：廖美成</p>
        <p class="p">电话：+86-18503082327</p>
<!--         <p class="p">微信：W000222555</p>
        <p class="p">邮箱：weiweiding@weiwei.com</p> -->
      </div>
      <div class="box">
        <h5 class="h5">推广业务合作</h5>
        <p class="p">---</p>
        <p class="p">联系人：廖美成</p>
        <p class="p">电话：+86-18503082327</p>
      <!--   <p class="p">微信：W000222555</p>
        <p class="p">邮箱：weiweiding@weiwei.com</p> -->
      </div>
      <div class="box">
        <h5 class="h5">客户合伙人</h5>
        <p class="p">---</p>
        <p class="p">联系人：廖美成</p>
        <p class="p">电话：+86-18503082327</p>
<!--         <p class="p">微信：W000222555</p>
        <p class="p">邮箱：weiweiding@weiwei.com</p> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'aboutus-cnt2',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.aboutus-cnt2 {
  width: 946px;float:right;background: #fff;border-radius: 5px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .box {
      border-bottom:1px dashed #ccc;padding-bottom:30px;margin-bottom:30px;
      .h5 {
        font-size: 16px;color:#262626;margin-bottom:10px;
      }
      .p {
        font-size: 16px;color:#555555;line-height: 30px;margin-bottom:5px;
      }
      &:last-child {
        margin-bottom:0px;border-bottom:0px;
      }
    }
  }
}
</style>
